import React from "react"
import { Helmet } from "react-helmet"
import { Container, Row, Col, Table } from "reactstrap"
import { ContactForm, Layout } from "../../../components"
import styled from "styled-components"

import {
  Medailon,
  BarChart,
  PaddingWrapper,
  ReferenceContainer,
  TitleH2,
  TitleH3,
  Timeline,
  BlockImageTextHalf,
  HalfWidthBlock,
} from "@igloonet-web/shared-ui"
import ReferenceBanner from "@igloonet-web/shared-ui/components/reference/reference-banner"
import ReferenceBannerContent from "@igloonet-web/shared-ui/components/reference/reference-banner-content"

import Sira from "../../../images/reference/planetaher/sira.png"
import Logo from "../../../images/reference/planetaher/logo-planeta.png"
import Top from "../../../images/reference/planetaher/top.png"
import Header from "../../../images/reference/planetaher/header-marketing.png"
import Karticky from "../../../images/reference/planetaher/karticky.png"
import Figurky from "../../../images/reference/planetaher/figurky.png"

const SpanWithPadding = styled.span`
  padding-bottom: 1rem;
`

const Th = styled.th`
  @media (max-width: ${(props) => props.theme.sizes_max.md}) {
    font-size: 0.65rem;
  }
`

const pozadi = {
  backgroundImage: `linear-gradient(rgba(100,200,150,0.9),rgba(0,49,85,0.9)),url(${Top})`,
}

const state = {
  labels: ["2017 vs 2018", "2018 vs 2019", "2019 vs 2020"],
  datasets: [
    {
      label: "Růst tržeb (meziroční změna)",
      data: [14.33, 22.12, 70.23],
      backgroundColor: ["#e87722", "#e87722", "#e87722"],
      datalabels: {
        color: "#0f1637",
        anchor: "end",
        align: "top",
        font: function (context) {
          var width = context.chart.width
          var size = Math.round(width / 42)
          return {
            size: size,
          }
        },
        formatter: (value) => {
          return value + " %"
        },
      },
    },
    {
      label: "PNO (meziroční změna)",
      data: [10.14, -7.71, -10.77],
      backgroundColor: [
        "rgb(234 129 50 / 30%)",
        "rgb(234 129 50 / 30%)",
        "rgb(234 129 50 / 30%)",
      ],
      datalabels: {
        color: "#0f1637",
        anchor: "end",
        align: "top",
        font: function (context) {
          var width = context.chart.width
          var size = Math.round(width / 42)
          return {
            size: size,
          }
        },
        formatter: (value) => {
          return value + " % "
        },
      },
    },
  ],
}

const timeline = [
  {
    year: "2007",
    header: "Založení Planety Her",
  },
  {
    year: "2011",
    header: "Založení REXher",
  },
  {
    year: "2013",
    header: "Počátek spolupráce na propagaci s igloonetem",
    linkLabel: "Případová studie",
    odkaz: "//igloonet.cz/blog/pripadova-studie-planeta-her/",
    title: "Odkaz na případovou studii",
  },
  {
    year: "2014",
    header: "Ve správě máme všechny on-line kanály",
    text: "a přidáváme další",
  },
  {
    year: "2016",
    linkLabel: "Nový e-shop od igloonetu",
    odkaz: "//planetaher.cz/",
    title: "web planetaher.cz",
    text: "včetně hostingu",
  },
  {
    year: "2017",
    header: "Nasazení measurement protokolu a start pravidelného e-mailingu",
    text: "a přesun do větší prodejny",
  },
  {
    year: "2020",
    header: "První akce Zajíc v pytli v ČR",
    text: "Měření marží, nový věrnostní program",
  },
  {
    year: "2021",
    header: "Pomoc s obsahem a práce na redesignu webu",
  },
]

const Planeta = () => (
  <Layout>
    <Helmet>
      <title>Kompletní správa kampaní pro Planetu Her | igloonet.cz</title>
      <meta
        name="description"
        content="„Kampaně od igloonetu nám násobně zvedly obraty e-shopu a zásadně
            rozšířily povědomí o značce. Pokud hledáte firmu na on-line marketing,
            neznám lepší,“ Michal Široký, spolumajitel"
      />
    </Helmet>
    <ReferenceBanner
      styles={pozadi}
      logo={Logo}
      extodkaz="//planetaher.cz/"
      heading="Téměř dekáda úspěšné spolupráce s Planetou her"
      odstavec="listopad 2013 - současnost"
    >
      <ReferenceBannerContent
        image={{
          src: Header,
          alt: "zobrazení herních novinek na eshopu planetaher.cz v náhledu mobilního telefonu",
        }}
        mainText="Kamenný a internetový obchod se společenskými hrami Planeta Her rozehrál svou partii v roce 2007. Hned o tři roky později přibylo deskovkové vydavatelství REXhry, které od té doby vypustilo přes 200 her, dodávaných do 30 e-shopů a více jak 20 obchodů."
      />
    </ReferenceBanner>

    <ReferenceContainer>
      <PaddingWrapper>
        <p>
          Od roku 2013 můžeme hrdě hlásit, že jim na cestě marketingem a
          propagací úspěšně pomáháme, o tři léta později jsme pro Planetu
          vyvinuli a spustili nový e-shop, který současně hostujeme.
        </p>
        <p>
          Ještě víc nás ale těší, že neřešíme jen prodeje a čísla, ale rádi se
          utkáme v přátelském souboji třeba nad partičkou nových her, co ještě
          nejsou v prodeji.
        </p>
        <p>
          Planetu můžete znát taky z její deskoherní akce Brnohraní nebo z
          dobročinných aukcí nedostupných her, pořádaných na severu
          Zatrolene-hry.cz.
        </p>
      </PaddingWrapper>

      <PaddingWrapper>
        <TitleH2>Milníky na cestě naší spolupráce</TitleH2>

        <Timeline data={timeline}></Timeline>
      </PaddingWrapper>

      <HalfWidthBlock>
        <PaddingWrapper>
          <TitleH2>Cíle spolupráce</TitleH2>
          <ul>
            <li>
              Hlavní marketingový cíl je jasný: Kontinuální meziroční růst tržeb
              a provozního zisku.
            </li>
            <li>
              V dílčí rovině pak jde o rozšiřování komunity a její
              informovanosti o novinkách či předobjednávkách.
            </li>
          </ul>
        </PaddingWrapper>
      </HalfWidthBlock>

      <PaddingWrapper>
        <TitleH2>Rozsah marketingové spolupráce</TitleH2>
        <Container className="text-center mt-5">
          <Row className="mb-5">
            <Col
              md={6}
              className="d-flex flex-column align-items-center text-center"
            >
              <TitleH3 className="p-4 d-inline">Propagace</TitleH3>
              <SpanWithPadding>Facebook Ads</SpanWithPadding>
              <SpanWithPadding>Heureka a Zboží, Beed</SpanWithPadding>
              <SpanWithPadding>Google Ads a Seznam Sklik</SpanWithPadding>
              <SpanWithPadding>
                E-mailing na Mailkitu včetně automatiky
              </SpanWithPadding>
              <SpanWithPadding>Správa produktových feedů</SpanWithPadding>
              <SpanWithPadding>Transakční e-maily</SpanWithPadding>
              <SpanWithPadding>Push notifikace</SpanWithPadding>
            </Col>
            <Col md={6} className="d-flex flex-column align-items-center">
              <TitleH3 className="p-4 d-inline">Analytika a web</TitleH3>
              <SpanWithPadding>Pomoc s UX a SEO</SpanWithPadding>
              <SpanWithPadding>Google Analytics a GTM</SpanWithPadding>
              <SpanWithPadding>Klíčové analýzy, textace a blog</SpanWithPadding>
              <SpanWithPadding>
                Analýzy webu a konzultace jeho úprav
              </SpanWithPadding>
              <SpanWithPadding>Measurement protocol</SpanWithPadding>
              <SpanWithPadding>Cost importy</SpanWithPadding>
              <SpanWithPadding>Grafika</SpanWithPadding>
            </Col>
          </Row>
        </Container>
      </PaddingWrapper>

      <PaddingWrapper>
        <BlockImageTextHalf
          className="d-md-none"
          image={Figurky}
          alt={"herní figurky válečníků na hrací ploše"}
          right
          hideImageOnSmall
        >
          <p>
            Pro Planetu řešíme také hosting, pomohli jsme s vývojem e-shopu
            včetně například pokladního systému, automatizace doporučování
            produktů nebo napojení na Mall. Shop jsme Planeťákům předali v roce
            2021 do vlastní správy.
          </p>
          <p>
            A sami se starají také o příspěvky na sociální sítě a video obsah,
            protože nejlépe znají a vnímají svoji komunitu. Od nás nicméně
            získávají feedback a reklamní podporu své práce, aby se dostala co
            možná nejdál.
          </p>
        </BlockImageTextHalf>
      </PaddingWrapper>

      <PaddingWrapper>
        <Medailon
          wide
          image={Sira}
          alt=""
          text="igloonet je pro nás super partner jak lidsky, tak pracovně.
            Jejich kampaně nám násobně zvedly obraty e-shopu a zásadně rozšířily
            povědomí o značce. Pokud hledáte firmu na on-line marketing, neznám lepší."
          name="Michal Široký"
          position="spolumajitel"
        />
      </PaddingWrapper>

      <PaddingWrapper>
        <TitleH2>Nahlédnutí do výsledků spolupráce</TitleH2>
        <TitleH3>Růst e-shopu v posledních letech</TitleH3>
        <Table>
          <thead>
            <tr>
              <Th scrope="row">Období</Th>
              <Th>Růst tržeb (meziroční změna)</Th>
              <Th>PNO (meziroční změna)</Th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td scrope="row">2017 vs. 2018</td>
              <td>+14,33 %</td>
              <td>+10,14 %</td>
            </tr>
            <tr>
              <td scrope="row">2018 vs. 2019</td>
              <td>+22,12 %</td>
              <td>-7,71 %</td>
            </tr>
            <tr>
              <td scrope="row">2019 vs. 2020</td>
              <td>+70,23 %</td>
              <td>-10,77 %</td>
            </tr>
          </tbody>
        </Table>
      </PaddingWrapper>

      <PaddingWrapper>
        <TitleH3>Růst e-shopu a změny v PNO v posledních letech</TitleH3>
        <BarChart data={state} />
      </PaddingWrapper>
      <PaddingWrapper>
        <p>
          Nutno dodat, že v roce 2020 popohnala vývoj výsledků v celém
          deskoherním světě pandemie COVID-19, resp. změna chování lidí při
          vládních opatřeních a lockdownech.
        </p>
        <p>
          Těchto změn jsme se také chytili v propagaci a reklamu museli v
          předvánočním období kvůli enormnímu náporu dokonce vypínat. Můžeme
          nicméně prozradit, že velmi zajímavý růst pokračuje i v celém roce
          2021, tedy v době rozvolnění a otevření prodejen.
        </p>
      </PaddingWrapper>

      <BlockImageTextHalf
        className="d-md-none"
        image={Karticky}
        alt={
          "stůl plný herních karet a ukazováček ruky hráče mířící na jednu z aktivních herních kartiček vyloženou na stole"
        }
        left
        hideImageOnSmall
      >
        <TitleH2>Nejbližší plány</TitleH2>
        <p>
          S růstem e-shopu a celé Planety přichází také nové příležitosti. Jak
          například v rozšiřování současných kanálů na větší a větší publika a
          silnější podporu brandu, tak ve výzkumech publik a jejich chování na
          e-shopu i mimo něj. S tím také souvisí třeba úprava a zpřesnění jeho
          menu či redesign.
        </p>
      </BlockImageTextHalf>
    </ReferenceContainer>

    <ContactForm contact="adam" />
  </Layout>
)

export default Planeta
